const ssqdata = [{
        citycode: [],
        adcode: "410000",
        name: "河南省",
        center: "113.753094,34.767052",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "440000",
        name: "广东省",
        center: "113.266887,23.133306",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "150000",
        name: "内蒙古自治区",
        center: "111.765226,40.818233",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "230000",
        name: "黑龙江省",
        center: "126.661998,45.742253",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "650000",
        name: "新疆维吾尔自治区",
        center: "87.628579,43.793301",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "420000",
        name: "湖北省",
        center: "114.341552,30.546222",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "210000",
        name: "辽宁省",
        center: "123.435093,41.836743",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "370000",
        name: "山东省",
        center: "117.020725,36.670201",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "610000",
        name: "陕西省",
        center: "108.953939,34.266611",
        level: "province",
        isLeaf: false
    },
    {
        citycode: "021",
        adcode: "310000",
        name: "上海市",
        center: "121.473667,31.230525",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "520000",
        name: "贵州省",
        center: "106.705251,26.600328",
        level: "province",
        isLeaf: false
    },
    {
        citycode: "023",
        adcode: "500000",
        name: "重庆市",
        center: "106.550483,29.563707",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "540000",
        name: "西藏自治区",
        center: "91.117449,29.648694",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "340000",
        name: "安徽省",
        center: "117.330139,31.734559",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "350000",
        name: "福建省",
        center: "119.296194,26.101082",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "430000",
        name: "湖南省",
        center: "112.982951,28.116007",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "460000",
        name: "海南省",
        center: "110.348781,20.018639",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "320000",
        name: "江苏省",
        center: "118.763563,32.061377",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "630000",
        name: "青海省",
        center: "101.780482,36.622538",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "450000",
        name: "广西壮族自治区",
        center: "108.327537,22.816659",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "640000",
        name: "宁夏回族自治区",
        center: "106.258889,38.472273",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "330000",
        name: "浙江省",
        center: "120.152575,30.266619",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "130000",
        name: "河北省",
        center: "114.530399,38.037707",
        level: "province",
        isLeaf: false
    },
    {
        citycode: "1852",
        adcode: "810000",
        name: "香港特别行政区",
        center: "114.173355,22.320048",
        level: "province",
        isLeaf: false
    },
    {
        citycode: "1886",
        adcode: "710000",
        name: "台湾省",
        center: "121.509062,25.044332",
        level: "province",
        isLeaf: false
    },
    {
        citycode: "1853",
        adcode: "820000",
        name: "澳门特别行政区",
        center: "113.54909,22.198951",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "620000",
        name: "甘肃省",
        center: "103.826777,36.060634",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "510000",
        name: "四川省",
        center: "104.076452,30.651696",
        level: "province",
        isLeaf: false
    },
    {
        citycode: "022",
        adcode: "120000",
        name: "天津市",
        center: "117.201509,39.085318",
        level: "province",
        isLeaf: false
    },
    {
        citycode: "010",
        adcode: "110000",
        name: "北京市",
        center: "116.407387,39.904179",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "360000",
        name: "江西省",
        center: "115.816587,28.637234",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "220000",
        name: "吉林省",
        center: "125.325802,43.896942",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "140000",
        name: "山西省",
        center: "112.578781,37.813948",
        level: "province",
        isLeaf: false
    },
    {
        citycode: [],
        adcode: "530000",
        name: "云南省",
        center: "102.709372,25.046432",
        level: "province",
        isLeaf: false
    }
]
export {
    ssqdata
}